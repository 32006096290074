import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscoveryComponentIntegrationViewModel, DiscoveryComponentSignatureUser } from '../../../models';
import { Observable } from 'rxjs';
import { EditorToken, Guid } from '@flipto/shared';
import { filter, first } from 'rxjs/operators';
import { ComponentGroupType } from 'account-hybrid/features/discovery/enums/component-group-type.enum';
import { Store } from '@ngrx/store';
import { MultiLanguageComponent } from 'account-hybrid/common/components/multi-linguage/multi-language.component';
import { SpacesService } from 'account-hybrid/common/components/spaces';

@Component({
    selector: 'ft-discovery-component-integration',
    templateUrl: './integration.component.html'
})
export class IntegrationComponent extends MultiLanguageComponent implements OnInit {
    @Input() componentGroupType$: Observable<ComponentGroupType>;
    @Input() integrationViewModel: DiscoveryComponentIntegrationViewModel;
    @Input() users$: Observable<DiscoveryComponentSignatureUser[]>;
    @Input() isLoading$: Observable<boolean>;
    @Input() commitSuccess$: Observable<boolean>;
    @Output() commitAction = new EventEmitter<Partial<DiscoveryComponentIntegrationViewModel>>();
    signatureUserOptions: { label: string, value: string }[] = [];
    isCompanySpaceType = this.spacesService.isCompanySpace();

    bookingEngineUrlTokens: EditorToken[] = [
        {
            name: 'Product booking engine code',
            value: '{product-booking-engine-code}'
        },
        {
            name: 'Property booking engine code',
            value: '{property-booking-engine-code}'
        },
        {
            name: 'Fallback booking engine code',
            value: '{fallback-booking-engine-code}'
        },
        {
            name: 'Itinerary booking engine code',
            value: '{itinerary-booking-engine-code}'
        },
        {
            name: 'Region booking engine code',
            value: '{region-booking-engine-code}'
        },
        {
            name: 'Start date',
            value: '{start-date,format=MM/dd/yyyy}'
        },
        {
            name: 'End date',
            value: '{end-date,format=MM/dd/yyyy}'
        },
        {
            name: 'Nights',
            value: '{nights}'
        },
        {
            name: 'Rooms',
            value: '{rooms}'
        },
        {
            name: 'Beds',
            value: '{beds}'
        },
        {
            name: 'Adults',
            value: '{adults}'
        },
        {
            name: 'Children',
            value: '{children}'
        },
        {
            name: 'Guests',
            value: '{guests}'
        },
        {
            name: 'Promo Code',
            value: '{promo-code}'
        },
        {
            name: 'Short Code',
            value: '{short-code}'
        },
        {
            name: 'Language Code',
            value: '{language-code}'
        }
    ];

    constructor(store: Store, private spacesService: SpacesService) {
        super(store);
    }

    ngOnInit() {
        this.users$.pipe(
            filter(users => !!users?.length),
            first()
        ).subscribe(users => {
            this.createSignatureUserOptions(users);
        });
    }


    createSignatureUserOptions(users: DiscoveryComponentSignatureUser[]) {
        this.signatureUserOptions = users.map((user: DiscoveryComponentSignatureUser) => {
            return {
                label: user.fullName,
                value: user.companyUserUUID
            };
        });
        this.signatureUserOptions.unshift({ label: 'The team at [orgname]', value: Guid.Empty });
    }

    commit(settings: Partial<DiscoveryComponentIntegrationViewModel>) {
        if (settings?.hasOwnProperty('languageData')) {
            // @todo temp override for language variations
            this.commitAction.emit({
                ...settings,
                languageData: [...settings.languageData.map(language => ({
                    ...language,
                    languageCode: 'en'
                }))]
            });
            return;
        }
        this.commitAction.emit(settings);
    }

    getUser(users: DiscoveryComponentSignatureUser[], companyUserUUID: string) {
        return users.find(user => user.companyUserUUID === companyUserUUID);
    }

    onFacadeStrategyChange(isEagerLoading: boolean, data: DiscoveryComponentIntegrationViewModel) {
        if (isEagerLoading) {
            data.facadeDelayInMs = -1;
        } else {
            data.facadeDelayInMs = 0;
        }
    }
}
